<template>
    <div>
        <div class="" v-if="emptyCard">
            <div class="shop-nav-1">
                <p class="shop-nav-no-items-title">В Корзине ничего нет</p>

                <img class="burger-close" @click="closeDrawer" src="@/assets/img/burger-close.svg" />

            </div>

            <div class="empty-container">
                <img class="volan-shop" src="@/assets/img/volan-shop-card.svg" alt="">
                <p class="empty-text">для того что бы, добавить товары в корзину перейдите <router-link
                        class="shop-link" to="/shop">в
                        магазин</router-link>
                </p>
            </div>
        </div>

        <div v-else class="">
            <div class="shop-nav">
                <p class="shop-nav-no-items-title-1">Корзина <span class="items-total-count">/{{ this.orders.length }}
                        шт</span></p>

                <img class="burger-close" @click="closeDrawer" src="@/assets/img/burger-close.svg" />
            </div>
            <div class="items-container">
                <div class="card-container" v-for="(order, index) in orders" :key="order.id">
                    <div class="left-side">
                        <div class="first-left-container">
                            <p class="number-item">{{ index + 1 }}.</p>
                            <img v-if="order.images && order.images.length > 0 && order.images.some(image => image.type === 'Original')"
                                class="card-img"
                                :src="`https://api.volantime.kz/api/Image/GetResized?imageId=${order.images[0].id}&width=102&height=139&isKeepAspectRatio=true`" />
                            <img v-else src="@/assets/img/product-not-load.png" class="card-img">
                        </div>
                        <div class="second-left-container">
                            <p class="item-name">{{ order.name }}</p>
                            <div class="counter-container">
                                <div class="counter-btn" @click="decrement(order)">-</div>
                                <p class="total-count">{{ order.qty }}</p>
                                <div class="counter-btn" @click="increment(order)">+</div>
                            </div>
                            <p class="price">цена</p>
                            <p class="item-price" v-if="order.price">{{ order.price.toLocaleString('ru-RU') }} ТГ</p>
                            <p class="item-price" v-else>Не указана</p>
                        </div>
                    </div>
                    <div class="right-side">
                        <img class="delete-item" @click="deleteFromCart(order.id)" src="@/assets/icons/delete-item.svg"
                            alt="delete-item">
                    </div>
                </div>
                <v-divider class="divider" :thickness="2"></v-divider>

                <div class="total-container">
                    <p class="total-text-title">Сумма заказа</p>

                    <div class="line">
                        <span class="total-text">Общая стоимость товара(ов)</span>
                        <span class="dots"></span>
                        <span class="price-1">{{ total }} тг</span>
                    </div>

                    <button class="submit-application" @click="sendOrderToWhatsApp()">Оформить заказ</button>

                </div>
            </div>

        </div>

    </div>
</template>

<style scoped>
.submit-application {
    border-radius: 5px;
    color: #FFF;
    background: rgb(85, 15, 150);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 30px 10px 30px;
    margin-top: 36px;
    color: rgb(255, 255, 255);
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0%;
    text-align: center;
}

.submit-application:hover {
    color: rgb(255, 255, 255);
    background: #390E61;

}

.line {
    display: flex;
    align-items: baseline;
    white-space: nowrap;
}

.label {
    flex-shrink: 0;
}

.dots {
    flex-grow: 1;
    border-bottom: 1px dotted;
    margin: 0 10px;
    overflow: hidden;
}

.price-1 {
    flex-shrink: 0;
    color: black;
    font-family: Montserrat;
    font-weight: normal;
    font-size: 16px;

}

.total-text {
    color: black;
    font-family: Montserrat;
    font-weight: normal;
    font-size: 16px;
    text-align: left;

}

.total-text-title {
    color: black;
    font-family: Montserrat;
    font-weight: 700;
    font-size: 16px;
    text-align: left;
    padding-bottom: 11px;
}

.total-container {
    text-align: left;
    margin-top: 22px;
    margin-bottom: 25px;
}

.divider {
    margin-top: 14px;
}

.total-count {
    margin-left: 14px;
    margin-right: 14px;
}

.counter-btn {
    min-width: 30px !important;
    min-height: 30px !important;
    cursor: pointer;
    font-size: 20px;
    background-color: #D9D9D9;
    border-radius: 5px;
    color: white;

}

.counter-btn:hover {
    background: #550F96;
}

.counter-container {
    display: flex;
    align-items: center;
    margin-top: 14px;
    margin-bottom: 22px;
}

.card-img {
    width: 116px;
    height: 159px;
}

.delete-item {
    cursor: pointer;
}

.item-price {
    color: black;
    font-family: Montserrat;
    font-weight: 700;
    font-size: 24px;
    text-align: left;
}

.price {
    color: #858585;
    font-family: Montserrat;
    font-weight: normal;
    font-size: 20px;
    text-align: left;
}

.item-name {
    color: black;
    font-family: Montserrat;
    font-weight: 600;
    font-size: 18px;
    text-align: left;
}

.number-item {
    color: black;
    font-family: Montserrat;
    font-weight: 700;
    font-size: 18px;
    text-align: left;
    margin-right: 7px;
}

.left-side {
    display: flex;
}

.second-left-container {
    display: flex;
    flex-direction: column;
}

.first-left-container {
    display: flex;
    margin-right: 15px;
}

.card-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 37px;
}

.shop-nav-no-items-title-1 {
    font-family: Days One;
    font-size: 36px;
    color: black;
    font-weight: 700;
    text-transform: uppercase;
    text-align: left;
}

.items-total-count {
    font-family: Days One;
    font-size: 20px;
    color: black;
    font-weight: 700;
    text-transform: uppercase;

}

.shop-link {
    color: black;
    text-decoration: underline;
    font-family: Montserrat;
    font-weight: normal;
    cursor: pointer;
}

.empty-text {
    margin-top: 30px;
    font-family: Montserrat;
    font-size: 18px;
    font-weight: normal;
    max-width: 528px;
}

.empty-container {
    display: flex;
    flex-direction: column;
    text-align: left;
    margin-top: 31px;


}

.volan-shop {
    width: 70.85px;
    height: 93.75px;
}

.burger-close {
    margin-bottom: 25px;
    cursor: pointer;
}

.shop-nav-no-items-title {
    font-family: Days One;
    font-size: 36px;
    color: black;
    font-weight: 700;
    text-transform: uppercase;
    width: 266px;
    text-align: left
}

.shop-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    /* Фиксированное позиционирование */
    top: 0;
    /* Закрепляем элемент в верхней части экрана */
    left: 0;
    /* Закрепляем элемент слева */
    width: 100%;
    /* Ширина элемента по всей ширине экрана */
    z-index: 1000;
    background-color: white;
    padding: 35px 40px 10px 34px;
}

.shop-nav-1 {
    display: flex;
    justify-content: space-between;
    align-items: center;

}

.items-container {
    margin-top: 50px;
}

@media screen and (max-width:425px) {
    .item-price {
        font-size: 15px;
    }

    .shop-nav-no-items-title-1 {
        font-size: 26px;
    }

    .line {
        flex-direction: column;
        font-size: 12px;
    }



}
</style>
<script>
import { mapState } from 'vuex';
import { mapGetters } from "vuex";
export default {
    name: "shopCard",
    data() {
        return {
            // emptyCard: false,
            count: 1,
            price: 25000,
            ordersNum: null,
        };
    },
    created() {
        // this.checkCart();

        this.orders.forEach(order => {
            if (order.qty === undefined || order.qty <= 0) {
                // Устанавливаем начальное значение count если оно не указано
                this.$set(order, 'qty', 1);
            }
        });
    },
    computed: {
        ...mapGetters({
            orders: "orders",
        }),
        emptyCard() {
            return this.orders.length === 0;
        },
        total() {
            // Вычисляем общую стоимость всех товаров
            return this.orders.reduce((acc, order) => {
                const qty = Number(order.qty) || 1; // Убедитесь, что qty — число и минимум 1
                const price = Number(order.price) || 0; // Убедитесь, что price — число
                return acc + (price * qty);
            }, 0).toLocaleString('ru-RU');
        },
    },
    mounted() {
        console.log("покажи заказы" + this.orders);
        // Проверьте вывод в консоли
    },
    methods: {
        deleteFromCart(id) {
            this.$store.dispatch('removeFromCart', id)
        },
        sendOrderToWhatsApp() {
            const phoneNumber = '77773856887';
            // Заранее написанный текст
            let message = 'Здравствуйте! Интерeсуют данные товары:\n';

            this.orders.forEach((order, index) => {
                message += `${index + 1}. ${order.name} - ${order.qty} шт - ${order.price * order.qty}тг.\n`;
            });

            // Добавляем итоговую сумму заказа
            const totalAmount = this.orders.reduce((total, order) => total + (order.price * order.qty), 0);
            message += `Итого: ${totalAmount} тг.`;
            // Создание ссылки для WhatsApp
            const whatsappLink = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(message)}`;

            this.$store.dispatch('clearCart');
            // Открытие ссылки в новом окне
            window.open(whatsappLink, '_blank');

        },

        closeDrawer() {
            this.$emit('close-drawer');
        },

        increment(order) {
            console.log('Increment called for', order);
            if (order.qty !== undefined) {
                order.qty = Number(order.qty) + 1; // Увеличиваем qty
            }
        },
        decrement(order) {
            console.log('Decrement called for', order);
            if (order.qty !== undefined && order.qty > 1) {
                order.qty = Number(order.qty) - 1; // Уменьшаем qty, но не ниже 1
            }
        },
    },
}
</script>
