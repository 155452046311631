import Vue from "vue";
import Vuex from "vuex";
import VuexPersist from "vuex-persist";

const localStorage = new VuexPersist({
	key: "vuex",
	storage: window.localStorage,
	reducer: (state) => ({
		orders: state.orders,
	}),
});

Vue.use(Vuex);

export default new Vuex.Store({
	plugins: [localStorage.plugin],
	state: {
		orders: [], // Массив для хранения товаров в корзине
	},
	mutations: {
		ADD_TO_CART(state, product) {
			state.orders.push(product);
		},
		REMOVE_FROM_CART(state, productId) {
			state.orders = state.orders.filter((order) => order.id !== productId);
		},
		CLEAR_CART(state) {
			state.orders = [];
		},
		setOrders(state, orders) {
			state.orders = orders;
		},
	},
	actions: {
		addToCart({ commit }, product) {
			console.log("Adding product to cart:", product);
			commit("ADD_TO_CART", product);
		},
		removeFromCart({ commit }, productId) {
			commit("REMOVE_FROM_CART", productId);
		},
		clearCart({ commit }) {
			commit("CLEAR_CART");
		},
		fetchOrders({ commit, state }) {
			if (state.orders.length === 0 && data.orders.length > 0) {
				commit('setOrders', data.orders);
				// Убедитесь, что вы передаете массив заказов
			}
		}
	},
	getters: {
		orders: (state) => state.orders,
		isInCart: (state) => (productId) => {
			return state.orders.some((order) => order.id === productId);
		},
		cartItemCount: (state) => {
			return state.orders.length;
		},
	},
	modules: {},
});
