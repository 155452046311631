import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "./store";
import "./assets/global.css";
import axios from "axios";
// require("dotenv").config();
import "./assets/fonts/fonts.css";

// axios.defaults.baseURL = process.env.VUE_APP_API_HOST;
axios.defaults.baseURL = "https://api.volantime.kz";
// axios.defaults.baseURL = "https://api.volantime.dragau.kz/";
axios.interceptors.request.use((config) => {
	config.headers["Content-Type"] = "application/json";
	config.headers["Access-Control-Allow-Origin"] = "*";
	config.headers["Access-Control-Allow-Methods"] =
		"GET, POST, PUT, DELETE, OPTIONS";
	config.headers["Access-Control-Allow-Headers"] =
		"Origin, X-Requested-With, Content-Type, Accept, Authorization";
	// config.headers['CurrentLanguage'] = i18n.locale;
	return config;
});

Vue.config.productionTip = false;
Vue.prototype.$http = axios;
Vue.prototype.$store = store;

new Vue({
	router,
	store,
	vuetify,
	render: (h) => h(App),
}).$mount("#app");
