<template>
  <div id="app">
    <v-app>
      <Header @toggle-drawer="drawerNav = !drawerNav"></Header>
      <v-navigation-drawer :class="{ 'drawer-open': drawerNav }" app right v-model="drawerNav" temporary
        :width="drawerWidthShop" :height="drawerHeightShop">
        <shopCard @close-drawer="drawerNav = false"></shopCard>
      </v-navigation-drawer>
      <v-main>
        <router-view />
      </v-main>
      <Footer></Footer>
    </v-app>
  </div>
</template>

<style>
.v-navigation-drawer__content {
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}

.v-navigation-drawer__content::-webkit-scrollbar {
  width: 0;
  /* Убираем полосу прокрутки */
  background: transparent;
  /* Делаем фон прозрачным, если потребуется */
}

.no-scroll {
  overflow: hidden;
  position: fixed;
  width: 100%;
}

.drawer-open {
  z-index: 9999 !important;
  padding: 77px 40px 0px 35px !important;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

@media screen and (max-width: 392px) {
  .drawer-open {
    width: 100vw;
    padding: 50px 20px 0px 15px !important;
  }
}
</style>

<script>
import shopCard from "@/components/shopCard/shopCard.vue";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "App",
  components: {
    Header,
    Footer,
    shopCard
  },
  data() {
    return {
      drawerWidthShop: "38%",
      drawerHeightShop: "100%",
      drawerNav: false,
    };
  },
  methods: {
    handleResize() {
      if (window.innerWidth < 800) {
        this.drawerWidthShop = "100%";
      } else if (window.innerWidth < 1180) {
        this.drawerWidthShop = "60%";
      } else {
        this.drawerWidthShop = "38%";
      }
    }
  },
  mounted() {
    this.handleResize(); // Устанавливаем правильное значение при загрузке
    window.addEventListener('resize', this.handleResize); // Добавляем слушатель на изменение размера окна
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize); // Удаляем слушатель при уничтожении компонента
  },
  watch: {
    drawerNav(newVal) {
      if (newVal) {
        document.body.classList.add('no-scroll');
      } else {
        document.body.classList.remove('no-scroll');
      }
    }
  }
};
</script>
