<template>
  <div>
    <div class="d-sm-block d-mb-block d-lg-none">
      <!-- position: relative; z-index: 100" -->
      <div class="header" style="">
        <div class="header-content-mobile">
          <div class="header-logo-mobile">
            <router-link to="/">
              <img class="header-logo-img" src="@/assets/img/header_logo.svg" alt="Header Logo" />
            </router-link>
          </div>

          <div class="right-burger-container">

            <div class="burger-menu-container">
              <a href="https://www.instagram.com/" target="_blank">
                <img class="shop-card" src="@/assets/img/insta-icon.svg" alt="insta-img" />
              </a>
            </div>

            <div @click="openWhatsApp" class="burger-menu-container">
              <img class="shop-card" src="@/assets/img/whatsapp-icon.svg" alt="whatsap-img" />
            </div>

            <div class="social-3" @click="toggleDrawer" style="margin-left: 17px;">
              <img class="shop-card" src="@/assets/icons/shop-card.svg" alt="shop-icon">
              <p class="cart-items" v-if="cartItemCount > 0">{{ cartItemCount }}</p>
            </div>
            <div @click="toggleMenu" class="burger-menu-container">
              <img src="@/assets/img/burger-icon.svg" alt="burger-img" />
            </div>
          </div>


          <v-navigation-drawer app right temporary v-model="menuVisible" :width="drawerWidth" :height="drawerHeight">
            <!-- Содержимое вашего бургер-меню -->
            <v-list>
              <div class="burger-header">

                <img src="@/assets/img/volan-logo-burger.png" alt="burger-logo" />
                <div @click="burgerClose" class="burger-close">
                  <img src="@/assets/img/burger-close.svg" alt="burger-close" />
                </div>
              </div>



              <div class="header-nav-list-mobile">
                <router-link to="/" class="nav-list-mobile">
                  <p class="nav-list-mobile">Главная</p>
                </router-link>
                <router-link to="/about" class="nav-list-mobile">
                  <p class="nav-list-mobile">О клубе</p>
                </router-link>
                <router-link to="/professionals" class="nav-list-mobile">
                  <p class="nav-list-mobile">Профессионалы</p>
                </router-link>
                <router-link to="/fans" class="nav-list-mobile">
                  <p class="nav-list-mobile">Любителям</p>
                </router-link>
                <router-link to="/funzone" class="nav-list-mobile">
                  <p class="nav-list-mobile">Фанзона</p>
                </router-link>
                <router-link to="/sponsors" class="nav-list-mobile">
                  <p class="nav-list-mobile">Спонсорам</p>
                </router-link>
                <router-link to="/shop" class="nav-list-mobile">
                  <p class="nav-list-mobile">Магазин</p>
                </router-link>
                <img class="burger-player" src="@/assets/img/burger-girl.png" alt="volley-player" />

              </div>

            </v-list>
            <div class="burger-footer">
              <v-divider class="divider"></v-divider>
              <div class="footer-burger-container">
                <img src="@/assets/img/instagram-burger.svg" alt="">
                <a href="https://www.instagram.com/volantime/" class="burger-link">@volantime</a>
              </div>
              <div class="footer-burger-container">
                <img src="@/assets/img/phone-burger.svg" alt="">
                <a href="tel:+77773856887" class="burger-link">+7(777) 385 68 87</a>
              </div>
              <div class="footer-burger-container">
                <img src="@/assets/img/mail-burger.svg" alt="">
                <a href="mailto:volantime@yandex.ru" class="burger-link">volantime@yandex.ru</a>
              </div>
            </div>

          </v-navigation-drawer>
        </div>
      </div>


    </div>
    <div class="d-none d-lg-block d-xl-block d-xxl-block">
      <div class="header" style="">
        <!-- position: relative; z-index: 100" -->
        <div class="header-content">
          <div class="header-logo">
            <router-link to="/">
              <img src="@/assets/img/header_logo.svg" alt="Header Logo" />
            </router-link>
          </div>
          <div class="header-nav-list">
            <router-link to="/about" class="nav-list" active-class="active-nav" style="text-decoration: none">
              <p class="nav-list-1">О клубе</p>
            </router-link>
            <router-link to="/professionals" class="nav-list" active-class="active-nav" style="text-decoration: none">
              <p class="nav-list-2">Профессионалы</p>
            </router-link>
            <router-link to="/fans" class="nav-list" active-class="active-nav" style="text-decoration: none">
              <p class="nav-list-3">Любителям</p>
            </router-link>
            <router-link to="/funzone" class="nav-list" active-class="active-nav" style="text-decoration: none">
              <p class="nav-list-4">Фанзона</p>
            </router-link>
            <router-link to="/sponsors" class="nav-list" active-class="active-nav" style="text-decoration: none">
              <p class="nav-list-5">Спонсорам</p>
            </router-link>
            <router-link to="/shop" class="nav-list" active-class="active-nav" style="text-decoration: none">
              <p class="nav-list-6">Магазин</p>
            </router-link>
          </div>
          <div class="social-network">
            <a href="https://www.instagram.com/volantime/" target="_blank" lass="social-1">
              <img class="shop-card" src="@/assets/img/insta-icon.svg">
            </a>
            <a href="tel:+77773856887" class="social-2">
              <img  class="shop-card" src="@/assets/img/whatsapp-icon.svg">
            </a>
            <div class="social-3" @click="toggleDrawer">
              <img class="shop-card" src="@/assets/icons/shop-cart.svg" alt="shop-icon">
              <p class="cart-items" v-if="cartItemCount > 0">{{ cartItemCount }}</p>
            </div>

          </div>
        </div>

      </div>


    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: "Header",
  data() {
    return {
      menuVisible: false,
      drawerWidth: "90%",
      drawerHeight: "100%",
      activeItem: null,
      drawer: false,
    };
  },
  created() {
    this.fetchOrders();
  },
  computed: {
    ...mapGetters(['cartItemCount']),  // Подключаем геттер из Vuex
  },
  methods: {
    ...mapActions(['fetchOrders']),
    toggleDrawer() {
      this.$emit('toggle-drawer');
    },
    setActive(item) {
      this.activeItem = item;
    },
    burgerClose() {
      this.menuVisible = !this.menuVisible;
    },
    toggleMenu() {
      this.menuVisible = !this.menuVisible;
    },
    openWhatsApp() {
      const phoneNumber = '77773856887';
      const message = 'Здравствуйте, пишу вам с вашего веб-сайта.';
      const whatsappLink = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(message)}`;
      window.open(whatsappLink, '_blank');
    },
  }
}
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.cart-items {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: -2.5px;
  font-size: 14px;
  right: 0;
  color: #fff;
  background-color: crimson;

  border-radius: 50%;
  width: 15px;
  height: 15px;
}

.shop-card {
  width: 25px;
  height: 25px;
}

::v-deep .v-navigation-drawer .v-divider {
  margin-bottom: 20px;
  border-color: black !important;
}

.footer-burger-container {
  padding-bottom: 20px;
}

.nav-list-mobile {
  color: black;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0;
  text-align: left;
  padding-top: 30px;
  text-decoration: none !important;

}

.burger-footer {
  margin-bottom: 60px;
  margin-top: 170px;

}

.footer-burger-container {
  display: flex;
  align-items: center;
}

.burger-link {
  text-decoration: none;
  color: black;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0;
  text-align: left;
  text-decoration: none !important;
  padding-left: 10px;

}

.burger-player {
  position: absolute;
  right: 0;
  margin-top: -145px;
  z-index: 1;
}

.header-nav-list-mobile {
  text-align: left;
  position: relative;
  z-index: 100;
}

::v-deep nav {
  padding: 27px 0px 30px 22px !important;
}

.burger-header {
  display: flex;
  justify-content: space-between;

}



.right-burger-container {
  display: flex;
  align-items: center;
}


/* Стили для псевдоэлементов before */
.nav-list p::before {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 0;
  width: 100%;
  height: 100px;
  background-color: black;
  z-index: -1;
  opacity: 0;
  transition: opacity 0.5s ease;
}

.theme--light.v-navigation-drawer {
  background-color: #FFD500;
  z-index: 101;
}

/* Стили для активного элемента */
.active-nav p::before {
  opacity: 1;
}

.active-nav p {
  color: rgb(255, 255, 255);
}

/* Стили для hover-эффекта */
.nav-list p:hover::before {
  opacity: 1;
}

.header {
  background-color: #FFD500;
}

.social-2 {
  margin-left: 10px;
}

.social-3 {
  position: relative;
  margin-left: 10px;
  cursor: pointer;
}

.sub-category-1 {
  margin-top: 10px;
}

.sub-category-container {
  margin-left: 15px;
}

.sub-category {
  color: var(--01, #603725);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.horizontal-line-sub {
  width: 100%;
  /* Ширина полоски будет равной ширине родительского контейнера */
  height: 1px;
  /* Высота полоски (можно изменить на ваше усмотрение) */
  margin-top: 10px;
  background: var(--05, #dae3ea);
}

.arrow-close-open {
  padding-top: 18px;
  margin-right: 22px;
}

.btn-arrow {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.switch_eng-mobile:hover {
  color: var(--06, #bcb648);
  font-weight: 600;
}

.switch_ru-mobile:hover {
  color: var(--06, #bcb648);
  font-weight: 600;
}

.switch_ru-mobile:after {
  display: block;
  position: absolute;
  left: 0;
  width: 0px;
  height: 2px;
  background-color: #bcb648;
  content: "";
  transition: width 0.5s ease-out;
  color: #000000;
  margin-top: 9px;
}

.switch_eng-mobile:after {
  display: block;
  position: absolute;
  left: 0;
  width: 0px;
  height: 2px;
  background-color: #bcb648;
  content: "";
  transition: width 0.5s ease-out;
  color: #000000;
  margin-top: 9px;
}

.switch_ru-mobile {
  color: var(--Gray-5, #e0e0e0);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  /* 137.5% */
  letter-spacing: -0.408px;
  text-transform: lowercase;
}

.switch_eng-mobile {
  color: var(--Gray-5, #e0e0e0);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  /* 137.5% */
  letter-spacing: -0.408px;
  text-transform: lowercase;
}

::v-deep .v-navigation-drawer__content {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
}

.gap-container {
  display: flex;
  justify-content: space-between;
  /* Равномерное распределение пространства между дочерними элементами */
  flex-direction: column;
}

.burger-bot-img-container {
  text-align: center;
  /* margin-top: 45vh; */
}

.horizontal-line {
  width: 100%;
  /* Ширина полоски будет равной ширине родительского контейнера */
  height: 1px;
  /* Высота полоски (можно изменить на ваше усмотрение) */
  margin-top: 18px;
  background: var(--05,
      #dae3ea);
  /* Цвет фона с использованием переменной и значения по умолчанию */
}

.link-container {
  margin-left: 26px;
}

.burger-close {
  text-align: end;
  margin-right: 18px;

}

.no-underline-mobile {
  text-decoration: none;
}

.nav-list--mobile {
  color: var(--01, #603725);
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-top: 18px;
}

.switch_eng-mobile {
  margin-left: 23px;
}

.burger-menu-container {
  margin-left: 20px;
}

.switch-language-mobile {
  display: flex;
}

.header-content-mobile {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 16px 0px 20px;
}

.active {
  color: rgb(255, 255, 255);
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0%;
}

.nav-list-1::before,
.nav-list-2::before,
.nav-list-3::before,
.nav-list-4::before,
.nav-list-5::before,
.nav-list-6::before {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 0;
  width: 100%;
  height: 100px;
  background-color: black;
  /* Черный фон */
  z-index: -1;
  /* Помещаем псевдоэлемент позади основного контента */
  opacity: 0;
  /* Начальная прозрачность */
  transition: opacity 0.5s ease;
}

.nav-list-2:hover::before,
.nav-list-1:hover::before,
.nav-list-3:hover::before,
.nav-list-4:hover::before,
.nav-list-5:hover::before,
.nav-list-6:hover::before {
  opacity: 1;
}

.nav-list-1:hover,
.nav-list-2:hover,
.nav-list-3:hover,
.nav-list-4:hover,
.nav-list-5:hover,
.nav-list-6:hover {
  color: rgb(255, 255, 255);
  ;
}

.nav-list-4:hover:after,
.nav-list-4:focus:after {
  width: 100%;
  color: rgb(255, 255, 255);
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0%;
  margin-top: 9px;
  margin: 0 15px;
}

.nav-list-3:hover:after,
.nav-list-3:focus:after {
  width: 100%;
  color: var(--06, #fff);
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  margin-top: 9px;
}

.nav-list-2:hover:after,
.nav-list-2:focus:after {
  width: 100%;
  color: var(--06, #fff);
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  margin-top: 9px;
}

.nav-list-1:hover:after,
.nav-list-1:focus:after {
  width: 100%;
  color: var(--06, #fff);
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  margin-top: 9px;
}

.nav-list-4 {
  margin-bottom: 0px;
  color: rgb(0, 0, 0);
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0%;
  margin-left: 49px;
  text-decoration: none;
  position: relative;
  cursor: pointer;
  min-width: 107px;
  background-color: transparent;
  z-index: 2;
}

.nav-list-6 {
  margin-bottom: 0px;
  color: rgb(0, 0, 0);
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0%;
  margin-left: 49px;
  text-decoration: none;
  position: relative;
  cursor: pointer;
  min-width: 106px;
  background-color: transparent;
  z-index: 2;
}

.nav-list-5 {
  margin-bottom: 0px;
  color: rgb(0, 0, 0);
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0%;
  margin-left: 49px;
  text-decoration: none;
  position: relative;
  cursor: pointer;
  min-width: 127px;
  background-color: transparent;
  z-index: 2;
}

.nav-list-3 {
  margin-bottom: 0px;
  color: rgb(0, 0, 0);
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0%;
  margin-left: 49px;
  text-decoration: none;
  position: relative;
  cursor: pointer;
  min-width: 129px;
  background-color: transparent;
  z-index: 2;
}

.nav-list-2 {
  margin-bottom: 0px;
  color: rgb(0, 0, 0);
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0%;
  margin-left: 49px;
  text-decoration: none;
  position: relative;
  cursor: pointer;
  min-width: 176px;
  background-color: transparent;
  z-index: 10;
}

.nav-list-1 {
  margin-bottom: 0px;
  color: rgb(0, 0, 0);
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0%;
  position: relative;
  text-decoration: none;
  cursor: pointer;
  min-width: 105px;
  margin-left: 40px;
  background-color: transparent;
  z-index: 2;
}

.header-nav-list {
  display: flex;
  flex-direction: row;
}

.header-content {
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-top: 4.62962962963vh; */
  width: 100%;
  padding: 20px 12px;
  margin-right: auto;
  margin-left: auto;
}

.header-logo {
  width: 101px;
}

.social-network {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-left: 20px;
}

.switch_ru {
  color: var(--05, #dae3ea);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  /* 137.5% */
  letter-spacing: -0.408px;
  cursor: pointer;
  margin-right: 23px;
  /* text-transform: lowercase; */
}

.switch_eng {
  color: var(--05, #dae3ea);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  /* 137.5% */
  letter-spacing: -0.408px;
  text-transform: lowercase;
  cursor: pointer;
  position: relative;
}

.switch_eng:hover {
  color: var(--06, #bcb648);
  font-weight: 600;
}

.switch_ru:hover {
  color: var(--06, #bcb648);
  font-weight: 600;
}

.switch_ru:after {
  display: block;
  position: absolute;
  left: 0;
  width: 0px;
  height: 2px;
  background-color: #bcb648;
  content: "";
  transition: width 0.5s ease-out;
  color: #000000;
  margin-top: 9px;
}

.switch_eng:after {
  display: block;
  position: absolute;
  left: 0;
  width: 0px;
  height: 2px;
  background-color: #bcb648;
  content: "";
  transition: width 0.5s ease-out;
  color: #000000;
  margin-top: 9px;
}

.sub-menu {
  background-color: white;
  border-radius: 10px;
  /* Закругленные углы */
  padding: 15px 0 15px 0;
  position: absolute;
  width: 343px;
  top: 11.156vh;
  left: 44%;
  z-index: 100;
  display: none;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  /* Тень для подменю */
}

/* .nav-list-2:hover + .sub-menu,
  .sub-menu:hover {
    display: block;
  } */

.sub-menu li {
  list-style: none;
  margin-bottom: 10px;
  margin-left: 20px;
  border-bottom: 1px solid var(--05, #dae3ea);
}

.redirect_category {
  text-decoration: none !important;
  color: var(--01, #603725);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: block;
  padding-bottom: 10px;
  /* Добавляем отступ снизу для разделителей */
  transition: color 0.3s ease-in-out;
}

.sub-menu li:last-child {
  border-bottom: none;
  margin-bottom: 0px;
  /* Убираем разделитель для последнего элемента */
}

.sub-menu a:hover {
  color: var(--06, #bcb648);
  /* Цвет при наведении */
}

@media screen and (max-width: 281px) {

  /* Задаем высоту картинки на фоне в vh (например, 50vh) */
  .burger-menu-container {
    margin-left: 20px;
  }

  .header-logo-img {
    width: 98px;
  }
}

@media (max-width: 1263px) {
  .header-content-mobile {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 17px 0px 16px
  }
}

@media screen and (max-width: 365px) {
  .header-content-mobile {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 17px 16px 0px 16px
  }
}

@media (max-width: 653px) {
  .header-content-mobile {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 17px 16px 0px 16px
  }
}


@media (min-width: 653px) and (max-width: 1263px) {
  .burger-footer {
    margin-bottom: 0px;
    margin-top: 180px;
  }
}
</style>