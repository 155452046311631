<template>
    <div class="footer">
        <div class="d-none d-lg-block d-xl-block d-xxl-block">
            <div class="footer-info">
                <div class="footer-content py-1">
                    <a class="insta-link" href="https://www.instagram.com/volantime/" target="_blank">
                        <img src="../assets/icons/instagram-footer.svg" class="footer-nav-img" alt="footer-nav-img" />
                        <p class="footer-text">
                            @volantime
                        </p>
                    </a>

                </div>
                <div class="footer-content py-1 ml-10">
                    <a class="insta-link" href="tel:+77773856887">
                        <img src="../assets/icons/phone-call.svg" class="footer-nav-img" alt="footer-nav-img" />
                        <p class="footer-text">+7(777) 385 68 87</p>
                    </a>

                </div>
                <div class="footer-content py-1 ml-10">
                    <a class="insta-link" href="mailto:volantime@yandex.ru">
                        <img src="../assets/icons/mail.svg" class="footer-nav-img" alt="footer-nav-img" />
                        <p class="footer-text">volantime@yandex.ru</p>
                    </a>
                </div>
            </div>
        </div>
        <div class="d-sm-block d-mb-block d-lg-none">
            <div class="footer-info-mobile">
                <div class="footer-content-mobile py-3">
                    <a class="insta-link" href="https://www.instagram.com/volantime/">
                        <img src="../assets/icons/instagram-footer.svg" class="footer-nav-img" alt="footer-nav-img" />
                        <p class="footer-text-mobile">
                            @volantime
                        </p>
                    </a>
                </div>
                <div class="footer-content py-3">
                    <a class="insta-link" href="tel:+77773856887">
                        <img src="../assets/icons/phone-call.svg" class="footer-nav-img" alt="footer-nav-img" />
                        <p class="footer-text-mobile">+7(777) 385 68 87</p>
                    </a>
                </div>
                <div class="footer-content py-3">
                    <a class="insta-link" href="mailto:volantime@yandex.ru">
                        <img src="../assets/icons/mail.svg" class="footer-nav-img" alt="footer-nav-img" />
                        <p class="footer-text-mobile">volantime@yandex.ru</p>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Footer",
};
</script>

<style scoped>
.insta-link {
    text-decoration: none;
}

.footer-text-mobile {
    color: rgb(255, 255, 255);
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0%;
    text-align: center;
}

.footer-info-mobile {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.footer {
    background-color: #550F96;
    padding: 24px;
}

.footer-info {
    display: flex;
    flex-direction: row;
    justify-content: center;

}

.footer-text {
    color: rgb(255, 255, 255);
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0%;
    text-align: center;
    margin-top: 17px;
}

@media screen and (max-width: 1264px) and (min-width: 568px) {
    .footer-info-mobile {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    .footer-content {
        margin-left: 32px;
    }
}
</style>